<!--
 * @Description: 下载任务中心
 * @Author: LiangYiNing
 * @Date: 2022-01-26 09:11:08
 * @LastEditTime: 2022-07-15 16:02:13
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="businessCode">
              <e6-vr-select
                v-model="searchForm.businessCode"
                :data="fileExportTaskBusinessTypeEnum"
                placeholder="导出业务类型"
                title="导出业务类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="invalidStatus">
              <e6-vr-select
                v-model="searchForm.invalidStatus"
                :data="invalidStatusEnum"
                placeholder="失效状态"
                title="失效状态"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="left" label="操作" width="120">
            <template slot-scope="scope">
              <span v-if="isInvalid(scope.row)">已失效</span>
              <e6-td-operate
                v-else-if="scope.row.fileId"
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getExportFileList, findDownList, downFile } from "@/api";
import { printError } from "@/utils/util";
import { exportExcelByUrl } from "@/utils/download";
export default {
  name: "downLoadCenter",
  components: {},
  data() {
    return {
      total: 0,
      nowTime: new Date().getTime(),
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      fileExportTaskBusinessTypeEnum: [], // 导出业务类型枚举
      invalidStatusEnum: [
        { id: 0, label: "失效" },
        { id: 1, label: "有效" }
      ],
      queryListAPI: getExportFileList,
      searchForm: {
        businessCode: "", //导出业务类型
        invalidStatus: "", //失效状态（0失效，1有效）
        createTime: [], //创建时间
        startTime: "", //开始时间
        endTime: "", //结束时间
        pageIndex: 1,
        pageSize: 20
        // sortIndex: "createdTime", // 默认创建时间排序
        // sortDir: "descending" // 默认降序
      },
      columnData: [
        {
          fieldName: "businessCodeName",
          display: true,
          fieldLabel: "导出业务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "exportState",
          display: true,
          fieldLabel: "状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "更新时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invalidTime",
          display: true,
          fieldLabel: "失效日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [{}]
    };
  },
  mixins: [listPage, listPageReszie, base],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    },
    dataList() {
      this.nowTime = new Date().getTime();
    }
  },
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 获取枚举信息
    async initData() {
      try {
        let enumKey = ["fileExportTaskBusinessType"];
        //获取枚举数据
        let promiseList = [findDownList(enumKey)];
        let [findDownListRes] = await Promise.all(promiseList);
        enumKey.map(item => {
          this[`${item}Enum`] = this.getFreezeResponse(findDownListRes, {
            path: `data.${item}`
          });
        });
      } catch (error) {
        printError(error);
      }
    },
    isInvalid(row) {
      if (!row.fileId) {
        return false;
      }
      if (!row.invalidTime) {
        return true;
      }
      let rowInvalidTime = new Date(
        row.invalidTime.replace(/-/g, "/")
      ).getTime();
      return rowInvalidTime < this.nowTime;
    },
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPropId;
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "下载到本地",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.downFile(row);
      }
    },
    //下载
    async downFile(row) {
      if (!row.fileId) {
        return;
      }
      try {
        this.loading = true;
        let res = await downFile({ id: row.fileId });
        let url = this.getFreezeResponse(res, {
          path: "data.fileUrl",
          defaultVal: ""
        });
        if (url) {
          exportExcelByUrl(url);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
